<template>
  <draggable
    :list="items"
    tag="tbody"
    :draggable="'.itemDraggable'"
    @end="onSort(items)"
  >

    <tr
      v-for="(item, index) in items"
      :key="index"
      class="itemDraggable"
    >
      <td width="30px">
        <v-icon
          small
          class="page__grab-icon"
        >
          mdi-cursor-move
        </v-icon>
      </td>
      <td>
        {{item.Title}}
      </td>
      <td>
        {{item.ORD}}
      </td>
      <td style="width:350px;">
        {{item.ID}}
      </td>
    </tr>
  </draggable>
</template>
<script>
  import draggable from 'vuedraggable'
  import utils from '@/services/utils'
  export default{
    components: {
      draggable,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      onSort: {
        type: Function,
        default: null,
      },
    },
    methods: {
      prepareURL (v) {
        if (!v) return
        else {
          const limit = 50
          if (v.length > limit) return `<br />${v.substring(0, limit)}...`
          else return `<br />${v}`
        }
      },
      handleConvertTime (v) {
        return utils.convertTime(v)
      }
    }
  }
</script>
